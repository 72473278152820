<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button class="filter-item" type="primary" @click="handleCreate">新增</el-button>

      <el-input class="filter-item" v-model="listQuery.device_id" placeholder="设备编号" style="width: 220px;" clearable/>

      <el-select class="filter-item" v-model="listQuery.type" placeholder="设备类型" style="width: 140px" clearable>
        <el-option label="在线支付" :value="1"></el-option>
        <el-option label="包餐抵扣码" :value="2"></el-option>
      </el-select>

      <el-button class="filter-item" type="primary" @click="handleFilter">搜索</el-button>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" fit highlight-current-row>
      <el-table-column label="ID" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>

      <el-table-column label="设备编号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.device_id }}
        </template>
      </el-table-column>

      <el-table-column label="设备类型" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type === 1" type="success">在线支付</el-tag>
          <el-tag v-else-if="scope.row.type === 2" type="warning">包餐抵扣码</el-tag>
        </template>
      </el-table-column>

      <el-table-column label="打印机编号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.printer_no }}
        </template>
      </el-table-column>

      <el-table-column label="设备备注" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.remark }}
        </template>
      </el-table-column>

      <el-table-column label="操作" width="200" align="center" fixed="right" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleUpdate(scope.row)">修改</el-button>

          <el-button type="danger" size="mini" @click="handleDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[5,10,20,30,50]" :page-size="listQuery.limit" :total="total" background layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <!-- 详情 -->
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="60%" :close-on-click-modal="false">
      <el-form ref="form" :rules="rules" :model="form" label-width="150px">
        <el-form-item label="设备编号" prop="device_id">
          <el-input v-model="form.device_id"></el-input>
        </el-form-item>

        <el-form-item label="设备类型" prop="type">
          <el-select v-model="form.type">
            <el-option label="在线支付" :value="1"/>
            <el-option label="包餐抵扣码" :value="2"/>
          </el-select>
        </el-form-item>

        <el-row :gutter="20" v-if="form.type === 2">
          <el-col :span="12">
            <el-form-item label="打印机编号" prop="printer_no">
              <el-input v-model="form.printer_no"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="打印机密钥" prop="printer_key">
              <el-input v-model="form.printer_key"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="设备备注" prop="remark">
          <el-input v-model="form.remark" type="textarea" :rows="3" maxlength="255" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading">确定</el-button>
      </div>
    </el-dialog>
    <!-- 详情 -->
  </div>
</template>

<script>
  import request from "@/utils/request";
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        list: null,
        total: null,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 10,
          shop_id: "",
          device_id: "",
          type: "",
        },
        dialogFormVisible: false,
        dialogStatus: "",
        textMap: {
          update: "修改",
          create: "新增",
        },
        btnLoading: false,
        form:{
          id: "",
          shop_id: "",
          device_id: "",
          type: "",
          printer_no: "",
          printer_key: "",
          remark: "",
        },
        rules: {
          device_id: [{ required: true, message: "设备编号不能为空", trigger: "change" }],
          type: [{ required: true, message: "设备类型不能为空", trigger: "change" }],
        },
      };
    },
    created() {
      this.listQuery.shop_id = parseInt(this.$route.query.shop_id || 0);
      this.getList();
    },
    computed: {
      ...mapGetters(["schools","school_id","user"])
    },
    methods: {
      getList() {
        this.listLoading = true;
        request({
          url: "/api/backend/shop/cashierDeviceList",
          method: "get",
          params: this.listQuery
        }).then(response => {
          this.list = response.data.data;
          this.total = response.data.total;
          this.listLoading = false;
        });
      },
      handleFilter() {
        this.listQuery.page = 1;
        this.getList();
      },
      handleSizeChange(val) {
        this.listQuery.limit = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.listQuery.page = val;
        this.getList();
      },
      resetForm() {
        this.form = {
          id: "",
          shop_id: this.listQuery.shop_id,
          device_id: "",
          type: "",
          printer_no: "",
          printer_key: "",
          remark: "",
        }
        this.btnLoading = false
      },
      handleCreate() {
        this.resetForm()
        this.dialogStatus = "create"
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs["form"].clearValidate() //清除校验结果
        })
      },
      handleUpdate(row) {
        this.resetForm()
        this.form = Object.assign({}, row)
        this.dialogStatus = "update"
        this.dialogFormVisible = true
        this.$nextTick(() => {
          this.$refs["form"].clearValidate() //清除校验结果
        })
      },
      saveData() {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            this.btnLoading = true
            request({
              url: "/api/backend/shop/cashierDeviceSave",
              method: "post",
              data: this.form
            }).then(() => {
              this.btnLoading = false
              this.dialogFormVisible = false
              this.$message({
                type: "success",
                message: "操作成功"
              });
              this.getList();
            }).catch(() => {
              this.btnLoading = false
            })
          }
        })
      },
      handleDelete(row) {
        this.$confirm("确认继续该操作", "提示", {
          type: "warning"
        }).then(() => {
          request({
            url: "/api/backend/shop/cashierDeviceDelete",
            method: "post",
            data: {
              id: row.id
            }
          }).then(()=>{
            this.getList();
            this.list.length <= 1 && this.listQuery.page > 1 ? this.listQuery.page-- : false;
            this.$message({
              type: "success",
              message: "操作成功"
            });
          })
        }).catch(() => {});
      },
    }
  };
</script>

<style rel="stylesheet/scss" lang="scss" scoped>

</style>
